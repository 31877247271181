import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import  './assets/icon/iconfont.js'
import router from './router'

import VueI18n from "vue-i18n";
import Cookie from "js-cookie";

import VueYoutube from 'vue-youtube'


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueI18n); // 通过插件的形式挂载
Vue.use(VueYoutube)


Vue.config.productionTip = false

Vue.use(ElementUI)

//修改语言
const i18n = new VueI18n({
    locale: "en-US", // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        "zh-CN": require("./common/lang/zh"), // 中文语言包
        "en-US": require("./common/lang/en"), // 英文语言包
    },
});

new Vue({
    router,i18n,
    render: h => h(App),
}).$mount('#app')


i18n.locale = Cookie.get("lang") || "en-US";
