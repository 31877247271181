export const m = {


  loginTitle: "邮箱",
  loginPassword: "密码",
  homelecture:"讲座",
  homegonggao:"官方公告",
  hometiaozhan:"项目",
  homepaihang:"成绩排行",
  homegerenxinxi:"个人信息",
  homexiaoxi:"消息",
  hometiaozhanchengji:"挑战成绩",
  homeanliyanshi:"演示安排",
  infolianxiyouxiang:"联系邮箱",
  infoshangchuantouxiang:"上传头像",
  messageTitle:"系统后台消息",
  messageProjectCode:"Exams",
  messageType:"请选择消息类型",
  messagexuesu:"学术问题",
  messageliucheng:"流程问题",
  messagexitong:"系统操作问题",
  messageshuju:"数据问题",
  messagejiangxiang:"奖项证书问题",
  messageqita:"其他问题",
  messagetijiao:"提交",
  messagexiaoxitixing:"请选择消息类型",
  messagesendsuccess:"发送成功",
  messagehuifu:"官方回复：",
  xiazaizhengshu:"下载证书",
  myzhengshu:"证书",
  mychengjidan:"成绩单",
  mydaochupdf:"导出PDF",

  cansaikemu:"报名科目",
  qingshuruneirong:"请输入内容",

  kaishishijian:"开始时间",
  weikaishi:"未开始",
  jinxingzhong:"进行中",
  yiwancheng:"已完成",
  jinrukaochang:"进入考场",

  readyTip:"进入正式考试前，请先进行设备检查，设备开启正常后方可进入考试：",
  readyTip1:"此准备室页面的设备开启仅为检测目的，正式进入考试界面后还需重新授权摄像头和完整屏幕分享",
  readyTip2:"摄像头开启正常",
  readyTip3:"重新开启",
  readyTip4:"[整个屏幕*]分享开启正常",
  readyTip5:"[身份证明**]拍照上传",
  readyTip6:"查看已上传照片",
  readyTip7:"上传身份证明照片",
  readyTip8:"*考试必须分享[整个屏幕]，而不能仅分享单个窗口或浏览器页面",
  readyTip9:"**身份证明指任何带有本人照片和姓名信息的官方证明文件（包括但不限于身份证、护照、驾照、学校学生证、社保卡或其他证明文件）;",
  readyTip10:"拍照时请将证件带有照片和姓名一面置于摄像头前保证照片名字可见（切勿离摄像头过近导致画面失焦模糊）;",
  readyTip11:"证件照片仅做监考检查使用，考试核分结束后5个工作日后从服务器删除。",
  readyTip12:"注意：参加考试必须使用桌面电脑（PC或Mac），不支持手机和平板；必须使用Google Chrome浏览器，并打开摄像头和整个屏幕分享供系统进行监考。",
  readyTip13:"不知道如何开启摄像头和整个屏幕分享？请点击查看",
  readyTip14:"《设备指南》",
  readyTip15:"考试期间不允许切屏（包括更换浏览器窗口、缩小浏览器、打开其他应用等），所有切屏均会后台记录并人工审查，切屏次数超过7次后将强行交卷。",
  readyTip16:"开始考试",
  readyTip17:"拍照",
  readyTip18:"预览",
  readyTip19:"照片",
  readyTip20:"提示：请将身份证件居中，保证画面聚焦清晰，可见照片和姓名，按'拍照'键确认",
  readyTip21:"重拍",
  readyTip22:"上传",


  zixunxiaoxi:"咨询消息",
  queding: "确定",
  quxiaobtn: "取消",


  kaoshijieshu:"考试已经结束！",
  chengjiweigongbu:"电子成绩单还未公布，请稍后再来查看",


  chonglian:"监考连接中断,请点击重新开启摄像头和整个屏幕分享连接监考通道",


  shexiangtouzhichi:"您当前浏览器不支持访问摄像头，无法进行考试",
  pingmuluzhi:"您当前浏览器不支持屏幕录制，无法进行考试",
  huoqushexiangtoushibai:"您当前设备获取摄像头失败，无法进行考试",
  luzhishibai:"您当前设备获取屏幕录制失败，无法进行考试",
  jianceyouwenti:"检测到监考录像已被中断，您无法在继续考试！",


  jiankaozhong:"监考中",
  shengyushijian:"剩余考试时间",
  chakandatu:"点击查看大图",
  jiazaishibai:"加载失败，点击请重试",
  
  zuhouyiti:"已经是最后一题啦",
  diyiti:"已经是第一题啦",
  
  diyiye:"已经是第一页了",
  zuihouyiye:"已经是最后一页了",
  yida:"已答",

  wanchengle:"您已经参加过此次考试，无需再进入考试，请知晓！",
  examTip:"该切屏次数仅为系统智能记录次数，监考人员将根据实时记录的屏幕共享画面进行人工复核。最终切屏记录及是否认定为违规行为以人工复核结果为准。若因为非故意作弊原因（包括弹窗、意外点击屏幕外区域等），可继续正常进行考试",
  kaoshitishi:"当前正在进行考试，退出后，考试试卷会自动提交，您确定要退出考试？",
  kaoshituichutixing:"确定",
  erciqeuretuichu:"二次确认：您确定要退出考试？",



  cailiaotihi:"页面左半边的材料可以翻页，点击“上一页/下一页”可进行试卷材料翻页。更多功能按钮在页面右下方。如果你看不到它们，请向下滚动页面至底部。",


  wangluoyichang:"网络异常，请检查网络!",
  wangluoyilianjie:"网络已连接!",

  idshangchuanshibai:"身份证上传失败，请重试！",
  idshangchaunchenggong:"身份证上传成功",
  yijingwanchengkaoshi:"您已完成考试",
  shebieyouwenti:"当前您设备有问题，无法进入考试！",

  qingxianshangchuanshenfen:"请先上传身份证明!",

  kaoshishijianmeidao:"考试开始时间未到，请再耐心等待一会",
  koashishijianguoshiwu:"考试入场时间已过，考试开始15分钟后不得再入场",
  huoququanxianzhong:"获取摄像头权限中",
  huoqupingmufenxiangzhong:"获取屏幕分享中",
  qingxuanzhequanbupingmu:"请选择分享整个屏幕",
  pingmugongxiangshiabia:"屏幕共享失败",









  systemName: "Project Guide System",
  systemDesc1: "The Project Guide System is",
  systemDesc2: "a professional management platform", //我的音乐
  systemDesc3: "for your working projects.", //朋友
  accountName: "账号：", //音乐人
  password: "密码：", //音乐人
  loginType: "类型：",
  pleaseChoose: "请选择：",
  login: "登录",
  jiaoshiguanli: "教师管理",
  xueshengguanli: "学生管理",
  xiangmuguanli: "项目管理",
  xufeiguanli: "续费管理",
  benxiaomoban: "本校模版",
  mobanshangdian: "模版商店",
  xitongshezhi: "系统设置",
  zhanghaoshezhi: "账号设置",
  xuexiaoxinxi: "学校信息管理",
  tishi: "提示",
  renyuan: "人员",
  shezhi: "设置",
  xiangmu: "项目",
  moban: "模版",
  fanhui: "回到首页",
  baocun: "保 存",
  quxiao: "取 消",
  sousuo: "搜 索",
  zuijingengxinshijian: "最近更新时间",
  bianji: "编辑",
  shanchu: "删除",
  zhankaimingxi: "展开明细",
  yincangmingxi: "隐藏明细",
  zhankaiquanbumokuaimingxi: "展开全部模块明细",
  yincangquanbumokuaimingxi: "隐藏全部模块明细",
  qiehuanzhiqingshuangshitu: "切换至清爽视图",
  qiehuanzhiliebiaoshitu: "切换至列表视图",
  xinzengrenwumokuai: "新增任务模块",
  gengduocaozuo: "更多操作",
  caozuo: "操作",
  bianjimobanxinxi: "编辑模版信息",
  mokuaipaixutiaozheng: "模块排序调整",
  pingfenbiaoshezhi: "评分表设置",
  mokuaimingxi: "模块明细",
  zaicichuxinzengmokuai: "在此处新增模块",
  guanli: "管理",
  yaoqiuqingdan: "要求清单",
  ziyuanqingdan: "资源清单",
  renwushuoming: "指示说明",
  jiaofuchengguo: "交付成果",
  shi: "是",
  fou: "否",
  yiji: "一级",
  erji: "二级",
  sanji: "三级",
  changwenbenshuru: "多行文本输入",
  duanwenbenshuru: "单行文本输入",
  xialaxuanzeshuru: "下拉选择输入",
  chunwenzixinxizhanshi: "纯文字信息展示",
  shangchuanwenjian: "上传文件",
  zhiliangjiancha: "质量检查",
  renwumokuaiming: "任务模块名",
  renwumokuaijibie: "任务模块级别",
  mokuaishuruleixing: "模块输入类型",
  zengjiaxuanxiang: "增加选项",
  xialaxuanzexuanxiang: "下拉选择选项",
  shifoukeshuruduogeneirong: "是否可输入多个内容",
  shifoujinzhishangchuanfujian: "是否禁止上传附件",
  bianjirenwumokuaixinxi: "编辑任务模块信息",
  xinzengrenwumokuaixinxi: "新增任务模块",
  yiji_note: "一级为最上层的级别，即框架的根目录",
  erji_note: "将自动从属于其前面最近的一级任务模块",
  sanji_note: "将自动从属于其前面最近的二级任务模块",
  blank_note:
    "无需学生在此模块输入任何内容，只起到提示的作用（常见的情形包括该模块下有更低级别的模块需要学生输入内容，该模块本身更多只是结构上的一个汇总）。选择此类型后，学生将无法在该模块下输入内容",
  textarea_note: "即多行文本输入，学生可输入段落性的文字进行详细描述或回答",
  input_note: "即单行文本输入，学生可输入不超过一行的简短文字进行简要回答",
  select_note:
    "即提供下拉菜单和有限的选项，让学生进行选择，规范学生提交的信息或答案",
  file_note: "即指明让学生上传文件，不能进行其他的输入",
  check_note: "即指明让学生在此模块进行质量检查，不做其他任何输入",
  multi_input_note:
    "即是否允许学生重复增加相同的输入框，以提交或列举多个同等类型的信息，显示更清晰的结构",
  multi_input_yes_note: "允许学生自行添加更多的输入框",
  multi_input_no_note: "学生只能在一个输入框内填写内容",
  instruction_note:
    "对这个模块的指导性文字，可包括向学生介绍这个模块是什么，解释为什么要填写这个模块，简单描述如何填写这个模块等",
  deliverable_note:
    "明确描述学生需要在此模块下交付的内容或成果，帮助学生明确自己的作业目标，防止学生不清楚或混淆要交付的内容;",
  disable_file_upload_note:
    "本系统所有模块默认学生可以根据需要在模块下上传文件或资料，若需要禁止学生在本模块下上传，请选择“是”",
  block_name_placeholder: "请输入任务模块名",
  block_instruction_placeholder: "请输入任务模块指示",
  block_deliverable_placeholder: "请输入模块交付成果",
  module_search_placeholder: "输入模块名称关键词",
  keyishuruduogeneirong: "可以添加多个输入框",
  zuidazishu: "最大字数",
  zuishaozishu: "最少字数",
  min_length_note:
    "即要求学生每个输入框需要输入的最少字数（英文为词数），不填即不作要求",
  max_length_note:
    "即要求学生每个输入框最多能输入的字数（英文为词数），不填即不作要求",
  paixu: "模块排序",
  mobantouxiang: "模版头像",
  mobanmingcheng: "模版名称",
  mobanjianjie: "模版简介",
  mobanjianjie_placeholder: "请输入模版的简介",
  mobanmingcheng_placeholder: "请输入项目模版的名称",
  xiaoxizhongxin: "消息中心",
  qiehuanyuyan: "切换语言",
  xiugaitouxiang: "修改头像",
  xiugaigerenmima: "修改个人密码",
  xiugaimima: "修改密码",
  xiugaiyouxiang: "修改邮箱",
  xiugaichenggong: "修改成功",
  zhanghaoxinxi: "账号信息",
  tuichudenglu: "退出登录",
  xuesheng: "学生",
  xuexiaozhidaolaoshi: "学校指导老师",
  xuexiaoguanliyuan: "学校管理员",
  guanliyuan: "平台管理员",
  dengluchenggong: "登录成功",
  qingshuruzhanghao: "请输入账号",
  qingshurumima: "请输入密码",
  qingxuanzedengluleixing: "请选择登录类型",
  quedingqiehuanyuyan: "确定切换语言吗?",
  qingxianxiugaimima: "请先前往修改密码",
  youxianghuomimacuowu: "邮箱或者密码错误",
  biaodanmeitianwanzheng: "某些信息没有填写，请填写完整后提交",
  qiehuanxitongyuyan: "切换系统语言",
  qingxuanze: "请选择",
  jiumima: "旧密码",
  xinmima: "新密码",
  qingshurujiumima: "请输入旧密码",
  qingquerenxinmima: "请确认新密码",
  querenmima: "确认密码",
  quren: "确认",
  fasongxiaoxi: "发送消息",
  fasong: "发送",
  yifasong: "已发送",
  xitonghuifu: "系统回复",
  xinyouxiang: "新邮箱",
  qingshuruxinyouxiang: "请输入新邮箱",
  quanbu: "全部",
  benxiaozijianmoban: "本校自建模板",
  benxiaogoumaimoban: "本校购买模板",
  benxiaozijianmoban_mark: "本校自建模板",
  benxiaogoumaimoban_mark: "本校购买模板",
  xinzengmoban: "新增模版",
  qiehuanzhiliebiaoshitu: "切换至列表视图",
  qiehuanzhisuoluetushitu: "切换至缩略图视图",
  qiehuanzhibiaogeshitu: "切换至表格视图",
  gengxinchenggong: "更新成功",
  xinzengchenggong: "新增成功",
  xinzengjianchaxiang: "新增检查项",
  bianjijianchaxiang: "编辑检查项",
  weidupaixu: "维度排序",
  tianjiaweidu: "添加维度",
  zongji: "总计",
  fenzhi: "分值",
  weidufenshu: "维度分数",
  weidumingcheng: "维度名称",
  weidujianjie: "维度简介",
  weidumingcheng_placeholder: "请输入维度名称",
  weidujianjie_placeholder: "请输入维度简介",
  weidudefen_placeholder: "请输入维度分值",
  pingfenbiaomingcheng: "评分表名称",
  pingfenbiaomingcheng_placeholder: "请输入评分表名称",
  xiugaipingfenbiaomingcheng: "修改评分表名称",
  mokuaijibenxinxi: "模块基本信息",
  zanwushuju: "暂无数据",
  shangchuan: "上传",
  jinggao: "警告",
  shifouchuangjianpingfenbiao: "还没有评分表，是否创建？",
  shangchuanchenggong: "上传成功",
  xiugaichenggong: "修改成功",
  paixuchenggong: "排序成功",
  yaoqiuleixing: "检查要求类型",
  yaoqiuneirong: "检查要求内容",
  check_content_placeholder: "请输入任务要求的具体内容",
  check_type_placeholder: "请为任务要求定义一个具体的类型，方便学生理解和归类",
  xuhao: "序号",
  ziyuanmingcheng: "资源名称",
  ziyuanleixing: "资源类型",
  ziyuanlianjie: "资源链接",
  shangchuanshijian: "上传时间",
  shangchuanfangshi: "上传方式",
  xinzengxiangmumoban: "新增项目模版",
  bianjixiangmumoban: "编辑项目模版",
  xiangmumobanmingbunengweikong: "项目模版名不能为空",
  xiangmumobanmiaoshubunengweikong: "项目模版描述不能为空",
  xuexiaoguanliyuan: "学校管理员",
  ziliao: "资料",
  PGxueyuan: "PG 学院",
  xiangmuming: "项目名",
  xinzeng: "新增",
  xueke: "学科",
  bumen: "部门",
  youxiang: "邮箱",
  xingbie: "性别",
  zhiwei: "职位",
  zaizhizhuangtai: "在职状态",
  yingwenming: "英文名",
  jiaoshixingming: "教师姓名",
  xinzengjiaoshixinxi: "新增教师信息",
  bianjijiaoshixinxi: "编辑教师信息",
  xing: "姓",
  ming: "名",
  xiansheng: "先生",
  nvshi: "女士",
  qingshuruxing: "请输入姓",
  qingshuruming: "请输入名",
  qingshuruyingwenming: "请输入英文名",
  qingxuanzebumen: "请选择部门",
  qingshuruzhiwei: "请输入职位",
  qingshuruyouxiang: "请输入邮箱",
  qingxuanzexueke: "请选择学科",
  sousuojiaoshixingming: "搜索教师姓名",
  tianjiabumen_note: "可在设置面板中先添加部门",
  tianjiaxueke_note: "可在设置面板中先添加学科",
  genghuanmobantouxiang: "更换模版头像",
  dianjianshangchuan: "点击上传",
  xiazai: "下载",
  fangda: "放大",
  suoxiao: "缩小",
  genghuantupian: "更换图片",
  zuoxuanzhuan: "左旋转",
  youxuanzhuan: "右旋转",
  qingchutupian: "清除图片",
  caijiantupian: "裁剪图片",
  tupianshangchuan_note: "仅JPG/PNG格式，单张图片不超过2MB",
  功能: "功能更新",
  商店: "商店更新",
  学院: "学院更新",
  服务: "服务更新",
  banjimingcheng: "班级名称",
  banjirenshu: "班级人数",
  xuenian: "学年",
  suoshulaoshi: "所属老师",
  chakan: "查看",
  qingxuanzelaoshi: "请选择老师",
  qingxuanzexuenian: "请选择学年",
  qingshurubanjimingcheng: "请输入班级名称",
  xinzengbanjixinxi: "新增班级信息",
  bianjibanjixinxi: "编辑班级信息",
  banjiguanli: "班级管理",
  先生: "先生",
  女士: "女士",
  beizhu: "备注",
  biyenianfen: "预计业年份",
  xueshengxingming: "学生姓名",
  xingyingwen: "姓英文",
  mingyingwen: "名英文",
  nan: "男",
  nv: "女",
  男: "男",
  女: "女",
  zhanghukaiqizhuangtai: "账户开启状态",
  xueshengsuoshubanji: "学生所属班级",
  xinzengxueshengxinxi: "新增学生信息",
  bianjixueshengxinxi: "编辑学生信息",
  qingshurubeizhu: "请输入备注",
  qingshuruxingyingwen: "请输入姓的英文",
  qingshurumingyingwen: "请输入名的英文",
  qingxuanzebiyenianfen: "请选择毕业年份",
  jibenxinxi: "基本信息",
  jiaoshixinxiguanli: "教师信息管理",
  jiaoshiliebiao: "教师列表",
  xueshengxinxiguanli: "学生信息管理",
  banjiguanli: "班级管理",
  xueshengliebiao: "学生列表",
  benxiaoxiangmuguanli: "本校项目管理",
  zaizuoxiangmu: "在做项目",
  wanjiexiangmu: "完结项目",
  mobankuguanli: "模版库管理",
  benxiaomobanku: "本校模版库",
  xitongshezhi: "系统设置",
  guanlishezhi: "管理设置",
  xuexiaodingdan: "学校订单",
  bumenshezhi: "部门设置",
  xuekeshezhi: "学科设置",
  dangxiaxuenianshezhi: "学年设置",
  bumen_placeholder: "请添加部门",
  xueke_placeholder: "请添加学科",
  xuenian_placeholder: "请选择当前学年",
  bumen_note: "输入部门名称，按回车键确认添加后保存",
  xueke_note: "输入学科名称，按回车键确认添加后保存",
  dangqianxuenian_note: "请设置当前所处的学年",
  xuexiaomingcheng: "学校名称",
  xuexiaoyingwenming: "学校英文名",
  xuexiaologo: "学校LOGO",
  guojia: "国家",
  xuexiaodizhi: "学校地址",
  xuexiaoyingwendizhi: "学校英文地址",
  youbian: "邮编",
  xuexiaoleixing: "类型",
  xuexiaoxueduan: "学龄段",
  xuexiaowangzhi: "网址",
  buchongxinxi: "补充信息",
  xiangmuzhuangtai: "项目状态",
  xiangmunianfen: "项目年份",
  xueshengfenzu: "学生分组",
  xiangmumingcheng: "项目名称",
  xiangmuzhouqi: "项目周期",
  fenpeijiaoshi: "分配教师",
  fenpeibanji: "分配班级",
  shifoukaifangbaoming: "是否开放报名",
  xiangmupici: "项目批次",
  xinzengfenzu: "新增分组",
  weifenzuxuesheng: "未分组学生",
  fenzu: "分组",
  weifenzurenshu: "未分组人数",
  xueshengxingming: "学生姓名",
  zuhao: "组号",
  xueshengmingdan: "成员名单",
  wanchengjindu: "项目完成进度",
  jianchajindu: "检查进度",
  zishuchangdu: "提交内容长度（字数)",
  pingyushuliang: "评语数量",
  huizong: "汇总",
  jianchazongbiao: "检查总表",
  xiugaifenzu: "修改分组",
  pingfen: "评分",
  pingjiang: "评奖",
  shengchengPDFbaogao: "生成PDF报告",
  xiugaifenzuxinxi: "修改分组信息",
  xiaozumingcheng: "小组名称",
  xiangmuxiaozu: "项目小组",
  qingxuanzezuocemokuai: "请选择左侧模块",
  tianjiapinglun: "添加评论",
  laoshipinglun: "老师评论",
  wu: "无",
  zuijinxiugaishijian: "最近修改时间",
  tijiao: "提交",
  benkechengyou: "本课程有",
  daosuitanglianxi: "道随堂练习",
  xueshengzuoda: "学生作答",
  quchushurukuang: "去除输入框",
  qingshuruneirong: "请输入内容",
  meiyoujilu: "没有记录",
  xiazaipdf: "下载PDF",
  jijianggengxin: "即将更新",
  shipinbofang: "视频播放",
  mokuaijishu: "模块计数",
  checklistjishu: "检查项计数",
  pingfenbiaojishu: "评分项计数",
  ziliaojishu: "资料数",
  yigoumai: "已购买",
  weigoumai: "未购买",
  fanhui: "返回",
  dinggou: "订购",
  高级版: "高级版",
  基本版: "基本版",
  精装版: "精装版",
  全面版: "全套版",
  wozhidaole: "我知道了",
  liaojiemobanbutongbanben: "了解项目模版的不同版本",
  高级版_sub: "基础版 + 标准检查项",
  基本版_sub: "提供基础框架模块",
  精装版_sub: "高级版 + 标准评估表",
  全面版_sub: "精装版 + 必要参考资料",
  高级版_intro:
    "高级版在基础版之上，额外提供了各个模块的标准检查项，方便学生自查、老师协查，让项目质量可以得到保证",
  基本版_intro:
    "基本版提供了模版框架中的基本构成模块和框架结构，可以让学校和学生快速上手项目。每个模块都有基本的文字指导。",
  精装版_intro:
    "精装版在高级版之上，额外提供了项目的标准评估表，方便老师可以对学生项目进行评估和评价",
  全面版_intro:
    "全面版在精装版基础之，额外提供了项目所需用到的参考文档资料或讲解视频，方便学生在项目过程中可以参考和学习",
  jianchaxiang: "检查项",
  pinggubiao: "评估表",
};
